import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['minFilter', 'maxFilter', 'results', 'panel', 'profile']
  static values = {
    savedCandidate: Boolean, 
    previousCandidateId: { type: Number, default: 0 }
  };
  
  toggle(event) {
    const { id, projectId } = event.params;

    if (id) {
      fetch('/candidate_viewed_events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ candidate_id: id, project_id: projectId })
      });
    }

    const companyProfileSideBarContainer = document.getElementById('companyProfileSideBarContainer') 
    if (companyProfileSideBarContainer) {
      companyProfileSideBarContainer.style.display = 'none';
    }

    const companyProfileSidebar = document.getElementById('companyProfileSideBar');
    if (companyProfileSidebar) {
      companyProfileSidebar.style.display = 'none';
    }

    const savedCandidate = this.savedCandidateValue
    const previousCandidateProfile = this.profileTargets.find(profile => profile.dataset.panelIdParam == this.previousCandidateIdValue)
    
    const closePanel = (id != null && id !== this.previousCandidateIdValue);
    
    this.previousCandidateIdValue = id;
    
    const openCandidateProfile = this.profileTargets.find(profile => profile.dataset.panelIdParam == id)
    
    if (this.hasMaxFilterTarget && this.maxFilterTarget.style.display === 'none' && !closePanel && !savedCandidate) {
      this.maxFilterTarget.style.display = 'block';
      this.minFilterTarget.style.display = 'none'

      if (companyProfileSideBarContainer && companyProfileSideBarContainer.style.display === 'none' && savedCandidate) {
        this.resultsTarget.className = "layout-column layout-column--6 scrollable candidates-scrollable-container"
      } else {
        this.resultsTarget.className = "layout-column layout-column--9 scrollable candidates-scrollable-container"
      }
      
      this.panelTarget.style.display = 'none';
      openCandidateProfile.style.display = 'none'; 
      this.scrollProfileIntoView(id);
    } else {
      if (previousCandidateProfile) {
        previousCandidateProfile.style.display = 'none';
      }

      this.profileTargets.forEach(profile => {
        if (profile.dataset.panelIdParam != id) {
          profile.style.display = 'none'
        }
      })

      if (!savedCandidate) {
        this.maxFilterTarget.style.display = 'none';
        this.minFilterTarget.style.display = 'block';
        this.resultsTarget.className = "layout-column layout-column--5 scrollable candidates-scrollable-container"
        this.panelTarget.style.display = 'block';
      } else {
        if (this.hasResultsTarget) {
          this.resultsTarget.className = "layout-column layout-column--6 scrollable candidates-scrollable-container"
        }
        if (this.hasPanelTarget) {
          this.panelTarget.style.display = 'block';
          this.panelTarget.className = 'layout-column layout-column--6 scrollable candidates-scrollable-container'
        }
      }
      openCandidateProfile.style.display = 'block';
      openCandidateProfile.scrollIntoView();
      this.scrollProfileIntoView(id, 'smooth');
    }
  }

  close(event) {
    const { id } = event.params;

    const currentOffset = this.resultsTarget.scrollTop;
    
    if (!this.savedCandidateValue) {
      this.maxFilterTarget.style.display = 'block';
      this.minFilterTarget.style.display = 'none'
      this.resultsTarget.className = "layout-column layout-column--9 scrollable candidates-scrollable-container"
      this.panelTarget.style.display = 'none';
    } else {
      if (this.hasPanelTarget) {
        this.panelTarget.style.display = 'none';
      }
      if (this.hasResultsTarget) {
        this.resultsTarget.className = "layout-column layout-column scrollable candidates-scrollable-container"
      }
    }

    this.scrollProfileIntoView(id);
  }

  scrollProfileIntoView(candidateId, scrollBehavior=null) {
    const candidateElement = document.querySelector(`li[data-panel-id-param="${candidateId}"]`);
    const containerElement = document.querySelector('.candidates-scrollable-container');
    const offsetElement = document.querySelector('.search-form-stats-results-and-pagination__search-stats');
    const offsetHeight = offsetElement
      ? offsetElement.offsetHeight
      : 0

    if (candidateElement && containerElement) {
      const scrollOffset = candidateElement.offsetTop - containerElement.offsetTop - offsetHeight;

      const scrollSettings = {
        top: scrollOffset
      }

      if (scrollBehavior) {
        scrollSettings.behavior = scrollBehavior;
      }
  
      containerElement.scroll(scrollSettings);
    }
  }  
};

